import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LinkedInConnection, LinkedInConversationList, LinkedInConversationParticipant, LinkedInFriendUpdate, LinkedInNormalizedParticipant } from "interfaces/linkedin";
import { LinkedInSalesConversationList } from "interfaces/linkedin-salesnavigator";
import { ConversationId } from "../types";

// Search Results Types
export interface SearchResults {
    conversations: LinkedInConversationList;
    salesNavigatorConversations: LinkedInSalesConversationList;
    people: LinkedInNormalizedParticipant[];
    isLoading: boolean;
    error: string | null;
}

export interface MatchingConversation {
  isLoading?: boolean;
  noMatchingConversation?: boolean;
  matchingConversation?: ConversationId | null;
}

export const convertParticipantUrnsToId = (participantUrns: string[]) => {
  return participantUrns.sort().join(',');
}

export const convertPartcipantsToId = (participants: LinkedInConversationParticipant[]) => {
  return convertParticipantUrnsToId(participants.map(p => p.hostIdentityUrn));
}
  
export interface SearchResultsState {
  results: Record<string, SearchResults>;
  loadingConnections: boolean;
  loadingJobChanges: boolean;
  recentConnections: LinkedInConnection[] | null;
  recentJobChanges: LinkedInFriendUpdate[] | null;
  participants: Record<string, MatchingConversation>;
  allConnections: Record<string, LinkedInConnection>;
  allJobChanges: Record<string, LinkedInFriendUpdate>;
  allResults: Record<string, LinkedInNormalizedParticipant>;
}
  
const searchResultsInitialState: SearchResultsState = {
  results: {},
  loadingConnections: false,
  loadingJobChanges: false,
  recentConnections: null,
  recentJobChanges: null,
  participants: {},
  allConnections: {},
  allJobChanges: {},
  allResults: {},
};
  
  // Search Results Slice
  export const searchResultsSlice = createSlice({
    name: 'linkedinSearchResults',
    initialState: searchResultsInitialState,
    reducers: {
      updateMatchingConversationInfo: (state, action: PayloadAction<{
        participants: string[],
        update: Partial<MatchingConversation>,
      }>) => {
        const participantsId = convertParticipantUrnsToId(action.payload.participants);
        state.participants[participantsId] = action.payload.update;
      },
      setSearchResults: (state, action: PayloadAction<{ 
        query: string;
        results: SearchResults;
      }>) => {
        const { query, results } = action.payload;
        state.results[query] = results;
        state.allResults = { ...state.allResults, ...Object.fromEntries(results.people.map(p => [p.hostIdentityUrn, p])) };
      },
      setSearchLoading: (state, action: PayloadAction<{
        query: string;
        isLoading: boolean;
      }>) => {
        const { query, isLoading } = action.payload;
        if (state.results[query]) {
          state.results[query].isLoading = isLoading;
        } else {
          state.results[query] = {
            conversations: { conversations: [], nextCursor: null, continues: false, mailboxName: null },
            salesNavigatorConversations: { conversations: [], nextPageStartsAt: new Date(), continues: false, filterName: 'INBOX' },
            people: [],
            isLoading,
            error: null
          };
        }
      },
      setSearchError: (state, action: PayloadAction<{
        query: string;
        error: string | null;
      }>) => {
        const { query, error } = action.payload;
        if (state.results[query]) {
          state.results[query].error = error;
        }
      },
      clearSearchResults: (state, action: PayloadAction<string>) => {
        delete state.results[action.payload];
      },
      clearAllSearchResults: (state) => {
        state.results = {};
      },
      setRecentConnections: (state, action: PayloadAction<LinkedInConnection[]>) => {
        state.recentConnections = action.payload;
        state.allConnections = { ...state.allConnections, ...Object.fromEntries(action.payload.map(p => [p.to.entityUrn, p])) };
      },
      setRecentJobChanges: (state, action: PayloadAction<LinkedInFriendUpdate[]>) => {
        state.recentJobChanges = action.payload;
        state.allJobChanges = { ...state.allJobChanges, ...Object.fromEntries(action.payload.map(p => [p.userId, p])) };
      },
      addRecentJobChange: (state, action: PayloadAction<LinkedInFriendUpdate[]>) => {   
        state.recentJobChanges = [...(state.recentJobChanges ?? []), ...action.payload.filter(p => !state.allJobChanges[p.userId])];
        state.allJobChanges = { ...state.allJobChanges, ...Object.fromEntries(action.payload.map(p => [p.userId, p])) };
      },
      addRecentConnection: (state, action: PayloadAction<LinkedInConnection[]>) => {   
        state.recentConnections = [...(state.recentConnections ?? []), ...action.payload.filter(p => !state.allConnections[p.to.entityUrn])];
        state.allConnections = { ...state.allConnections, ...Object.fromEntries(action.payload.map(p => [p.to.entityUrn, p])) };
      },
      setLoadingConnections: (state, action: PayloadAction<boolean>) => {
        state.loadingConnections = action.payload;
      },
      setLoadingJobChanges: (state, action: PayloadAction<boolean>) => {
        state.loadingJobChanges = action.payload;
      }
    }
  });

  // Export search actions
export const {
    setSearchResults,
    setSearchLoading,
    setSearchError,
    clearSearchResults,
    clearAllSearchResults,
    setRecentConnections,
    addRecentConnection,
    setRecentJobChanges,
    addRecentJobChange,
    updateMatchingConversationInfo,
    setLoadingConnections,
    setLoadingJobChanges
  } = searchResultsSlice.actions;