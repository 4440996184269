import React, { useState, useRef, useEffect } from 'react';
import { Check, ChevronDown, X, Search } from 'lucide-react';

interface Option {
  value: string;
  label: string;
  noFilterOut?: boolean;
}

interface MultiSelectProps {
  options: Option[];
  value: string[];
  onChange: (value: string[]) => void;
  onInputChange?: (value: string) => void;
  placeholder?: string;
}

export function SimplifiedMultiSelect({ options, value, onChange, placeholder = 'Select options...', onInputChange }: MultiSelectProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState('');
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [focusedIndex, setFocusedIndex] = useState(-1);

  // Reset component state when options change
  useEffect(() => {
    setFocusedIndex(-1);
  }, [JSON.stringify(options)]);

  // Explicit filtering logic
  const filteredOptions = React.useMemo(() => {
    return options.filter(option => {
      if (option.noFilterOut) return true;
      if (search === '') return true;
      return option.label.toLowerCase().includes(search.toLowerCase());
    });
  }, [options, search]);

  // Only filter if it's not a special option (like _SEARCH_, _SEARCHING_, etc)

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'ArrowDown') {
      e.preventDefault();
      setFocusedIndex(prev => Math.min(prev + 1, filteredOptions.length - 1));
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      setFocusedIndex(prev => Math.max(prev - 1, -1));
    } else if (e.key === 'Enter' && focusedIndex >= 0) {
      const option = filteredOptions[focusedIndex];
      toggleOption(option.value);
    } else if (e.key === 'Escape') {
      setIsOpen(false);
    }
  };

  const toggleOption = (optionValue: string) => {
    // Don't toggle special values
    if (optionValue.includes('_SEARCH_') || optionValue.includes('_SEARCHING_') || optionValue.includes('_NO_RESULTS_')) {
      return;
    }
    const newValue = value.includes(optionValue)
      ? value.filter(v => v !== optionValue)
      : [...value, optionValue];
    onChange(newValue);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleSearchChange = (newSearch: string) => {
    setSearch(newSearch);
    if (onInputChange) {
      onInputChange(newSearch);
    }
  };


  return (
    <div className="relative w-full" ref={containerRef}>
      <div
        className="border rounded-lg bg-white px-2 py-1.5 min-h-[38px] cursor-pointer flex flex-wrap gap-1 items-center"
        onClick={() => {
          setIsOpen(!isOpen);
          setTimeout(() => inputRef.current?.focus(), 0);
        }}
      >
        <div className="flex flex-wrap gap-1 items-center flex-1 min-w-0">
          {value.length > 0 ? (
            value.map(v => {
              const option = options.find(o => o.value === v);
              return (
                <span
                  key={v}
                  className="bg-blue-100 text-blue-800 rounded px-1.5 py-0.5 text-sm flex items-center gap-1 max-w-full"
                >
                  <span className="truncate">{option?.label}</span>
                  <X
                    size={14}
                    className="shrink-0 cursor-pointer hover:text-blue-600"
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleOption(v);
                    }}
                  />
                </span>
              );
            })
          ) : (
            <span className="text-gray-500 truncate">{placeholder}</span>
          )}
        </div>
        <ChevronDown size={18} className="shrink-0 text-gray-400 ml-auto" />
      </div>

      {isOpen && (
        <div className="absolute z-50 w-full mt-1 bg-white border rounded-lg shadow-lg">
          <div className="p-1.5 border-b">
            <div className="relative">
              <Search size={18} className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400" />
              <input
                ref={inputRef}
                type="text"
                className="w-full pl-7 pr-2 py-1.5 text-sm border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Search options..."
                value={search}
                onChange={(e) => handleSearchChange(e.target.value)}
                onKeyDown={handleKeyDown}
              />
            </div>
          </div>
          <div className="max-h-60 overflow-auto">
            {filteredOptions.map((option, index) => {
              return (
                <div
                  key={`${option.value}-${index}`}
                  className={`px-2 py-1.5 cursor-pointer flex items-center gap-2 text-sm ${
                    focusedIndex === index ? 'bg-blue-50' : 'hover:bg-gray-50'
                  }`}
                  onClick={() => toggleOption(option.value)}
                >
                  <div className={`shrink-0 w-4 h-4 border rounded flex items-center justify-center ${
                    value.includes(option.value) ? 'bg-blue-500 border-blue-500' : 'border-gray-300'
                  }`}>
                    {value.includes(option.value) && <Check size={12} className="text-white" />}
                  </div>
                  <span className="truncate">{option.label}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}