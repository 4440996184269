import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StaticFilter } from '../store';

export const SAVED_FILTERS_STORAGE_KEY = 'trellus_saved_filters';

interface SavedFilter {
  name: string;
  filter: StaticFilter;
  createdAt: string;
  lastModified: string;
}

interface SavedFiltersState {
  filters: { [key: string]: SavedFilter };
  activeFilterName: string | null;
}

const initialState: SavedFiltersState = {
  filters: JSON.parse(localStorage.getItem(SAVED_FILTERS_STORAGE_KEY) || '{}'),
  activeFilterName: null
};

export const savedFiltersSlice = createSlice({
  name: 'savedFilters',
  initialState,
  reducers: {
    saveFilter: (state, action: PayloadAction<{ name: string; filter: StaticFilter }>) => {
      const now = new Date().toISOString();
      state.filters[action.payload.name] = {
        name: action.payload.name,
        filter: action.payload.filter,
        createdAt: state.filters[action.payload.name]?.createdAt || now,
        lastModified: now
      };
      state.activeFilterName = action.payload.name;
    },
    deleteFilter: (state, action: PayloadAction<string>) => {
      delete state.filters[action.payload];
      if (state.activeFilterName === action.payload) {
        state.activeFilterName = null;
      }
    },
    setActiveFilter: (state, action: PayloadAction<string | null>) => {
      state.activeFilterName = action.payload;
    }
  }
});

export const { saveFilter, deleteFilter, setActiveFilter } = savedFiltersSlice.actions; 