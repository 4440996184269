import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LinkedInSalesConversation, LinkedInSalesMessage } from "interfaces/linkedin-salesnavigator";
import { ConversationId, ConversationIds, InboxType, SOURCE_TYPE } from "../types";

// Type to store conversations safely in Redux
export type ReduxSafeSalesMessage = Omit<LinkedInSalesMessage, 'deliveredAt'> & {
  deliveredAt: number;
};

export type ReduxSafeSalesConversation = Omit<LinkedInSalesConversation, 'nextPageStartsAt' | 'messages'> & {
  nextPageStartsAt: number | null;
  messages: ReduxSafeSalesMessage[];
};

export function convertToReduxSafeSalesMessage(message: LinkedInSalesMessage): ReduxSafeSalesMessage {
  return {
    ...message,
    deliveredAt: message.deliveredAt.valueOf()
  };
}

// Convert conversation to Redux safe format
export function convertSalesConversationToReduxSafe(conversation: LinkedInSalesConversation): ReduxSafeSalesConversation {
  return {
    ...conversation,
    nextPageStartsAt: conversation.nextPageStartsAt?.valueOf() ?? null,
    messages: conversation.messages.map(message => ({
      ...message,
      deliveredAt: message.deliveredAt.valueOf()
    }))
  };
}

// Convert conversation from Redux safe format
export function convertSalesConversationFromReduxSafe(conversation: ReduxSafeSalesConversation): LinkedInSalesConversation {
  return {
    ...conversation,
    nextPageStartsAt: conversation.nextPageStartsAt ? new Date(conversation.nextPageStartsAt) : null,
    messages: conversation.messages.map(message => ({
      ...message,
      deliveredAt: new Date(message.deliveredAt)
    }))
  } as LinkedInSalesConversation;
}

// State interfaces with proper typing
export interface SalesInboxState {
  conversationIds: ConversationIds;
  pageStartsAt: number | null;
  continues: boolean;
  isLoading: boolean;
  error: string | null;
  initialFetch: boolean;
}

export interface SalesConversationsState {
  conversations: Record<string, {
    conversation: ReduxSafeSalesConversation | null;
    isLoading: boolean;
    error: string | null;
  }>;
  conversationsLoaded: Set<string>;
  inboxState: Record<InboxType, SalesInboxState>;
}

// Initial states
const salesConversationsInitialState: SalesConversationsState = {
  conversations: {},
  inboxState: {
    [InboxType.ALL]: {
      conversationIds: [],
      pageStartsAt: null,
      continues: true,
      isLoading: false,
      error: null,
      initialFetch: false
    },
    [InboxType.UNREAD]: {
      conversationIds: [],
      pageStartsAt: null,
      continues: true,
      isLoading: false,
      error: null,
      initialFetch: false
    },
  },
  conversationsLoaded: new Set()
};

export const salesNavigatorConversationsSlice = createSlice({
  name: 'linkedinSalesNavigatorConversations',
  initialState: salesConversationsInitialState,
  reducers: {
    addSalesConversations: (state, action: PayloadAction<{ 
      inboxType: InboxType, 
      conversations: LinkedInSalesConversation[] 
    }>) => {
      const { inboxType, conversations } = action.payload;
      conversations.forEach(conversation => {
        if (!state.conversations[conversation.id]) {
          // Sort messages by deliveredAt before storing
          const sortedMessages = [...conversation.messages].sort((a, b) => a.deliveredAt.valueOf() - b.deliveredAt.valueOf());
          state.conversations[conversation.id] = {
            conversation: {
              ...convertSalesConversationToReduxSafe(conversation),
              messages: sortedMessages.map(message => convertToReduxSafeSalesMessage(message))
            },
            isLoading: false,
            error: null
          };
        }
      });
      const newConversationIds: ConversationIds = conversations.map(c => ({
        id: c.id,
        lastActivity: c.nextPageStartsAt?.valueOf() ?? 0,
        sourceType: SOURCE_TYPE.SALES_NAVIGATOR
      }))
      const allIds = Array.from(new Set([...state.inboxState[inboxType].conversationIds.map(c => c.id), ...newConversationIds.map(c => c.id)]));
      const allConversationIds = allIds.map(id => ({
        id,
        lastActivity: state.conversations[id].conversation?.nextPageStartsAt?.valueOf() ?? 0,
        sourceType: SOURCE_TYPE.SALES_NAVIGATOR
      })).sort((a, b) => {
        if (!state.conversations[a.id] || !state.conversations[b.id]) return 0;
        const aDate = state.conversations[a.id].conversation?.nextPageStartsAt?.valueOf() ?? 0;
        const bDate = state.conversations[b.id].conversation?.nextPageStartsAt?.valueOf() ?? 0;
        return bDate - aDate;
      });
      state.inboxState[inboxType].conversationIds = allConversationIds;
    },
    updateSalesInboxState: (state, action: PayloadAction<{
      inboxType: InboxType,
      update: Partial<SalesInboxState>
    }>) => {
      const { inboxType, update } = action.payload;
      state.inboxState[inboxType] = {
        ...state.inboxState[inboxType],
        ...update
      };
    },
    setSalesLoading: (state, action: PayloadAction<{
      inboxType: InboxType,
      isLoading: boolean;
    }>) => {
      const { inboxType, isLoading } = action.payload;
      state.inboxState[inboxType].isLoading = isLoading;
    },
    setSalesError: (state, action: PayloadAction<{
      inboxType: InboxType,
      error: string | null;
    }>) => {
      const { inboxType, error } = action.payload;
      state.inboxState[inboxType].error = error;
    },
    resetSalesInbox: (state, action: PayloadAction<{
      inboxType: InboxType;
    }>) => {
      const { inboxType } = action.payload;
      state.inboxState[inboxType] = {
        conversationIds: [],
        pageStartsAt: null,
        continues: true,
        isLoading: false,
        error: null,
        initialFetch: false
      };
    },
    updateSalesConversation: (state, action: PayloadAction<{
      id: string;
      update: Partial<{
        conversation: ReduxSafeSalesConversation | null;
        isLoading: boolean;
        error: string | null;
      }>;
    }>) => {
      const { id, update } = action.payload;
      state.conversations[id] = {
        ...(state.conversations[id] || {}),
        ...update
      };
      if (update.conversation) state.conversationsLoaded.add(id);
    },
    addToSalesConversation: (state, action: PayloadAction<{
      id: string;
      message: ReduxSafeSalesMessage;
    }>) => {
      const { id, message } = action.payload;
      const conversation = state.conversations[id]?.conversation;
      if (conversation && conversation.messages) {
        // check if the message is already in the conversation
        const existingMessage = conversation.messages.find(m => m.id === message.id);
        if (existingMessage) return;
        conversation.messages.push(message);
        // Sort messages by deliveredAt after adding new message
        conversation.messages.sort((a, b) => a.deliveredAt - b.deliveredAt);
      }
    },
    updateSalesConversationReadState: (state, action: PayloadAction<{
      id: string;
      read: boolean;
    }>) => {
      const { id, read } = action.payload;
      const conversation = state.conversations[id]?.conversation;
      if (!conversation) return;
      conversation.unreadMessageCount = read ? 0 : 1;
    },
    removeFromSalesInbox: (state, action: PayloadAction<{
      inboxType: InboxType;
      id: string;
    }>) => {
      const { inboxType, id } = action.payload;
      state.inboxState[inboxType].conversationIds = state.inboxState[inboxType].conversationIds.filter(c => c.id !== id);
    },
    addToSalesInbox: (state, action: PayloadAction<{
      inboxType: InboxType;
      conversation: ConversationId;
    }>) => {
      const { inboxType, conversation } = action.payload;
      state.inboxState[inboxType].conversationIds.push(conversation);
    }
  },
});

export const {
  addSalesConversations,
  updateSalesInboxState,
  setSalesLoading,
  setSalesError,
  resetSalesInbox,
  updateSalesConversation,
  addToSalesConversation,
  updateSalesConversationReadState,
  removeFromSalesInbox,
  addToSalesInbox
} = salesNavigatorConversationsSlice.actions;