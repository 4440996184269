import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ConversationId } from "../types";

export const LINKEDIN_STATES_STORAGE_KEY = 'linkedin_states_vF';

export interface ConversationState {
    tags: string[];
    note: string | null;
    snooze: number | null;
    isArchived: boolean;
    exchangeState: ConversationExchangeState | null;
    analyzed_version: string | null;
    lastAnalyzedAt: number | null;
    conversationId: ConversationId;
    hadMoreThanOneMessage: boolean;
    lastFetchedAt: number | null;

    isLastMessageFromRep: boolean | null;
    lastMessageFromRepAt: number | null;
    lastMessageFromProspectAt: number | null;
}

export interface ConversationStatesState {
    states: Record<string, ConversationState>;
}

export const ANALYZED_VERSION = 'V2'

const emptyConversationState: (conversationId: ConversationId) => ConversationState = (conversationId) => {
  return {
    tags: [],
    note: null,
    snooze: null,
    isArchived: false,
    exchangeState: null,
    lastAnalyzedAt: null,
    analyzed_version: null,
    conversationId: conversationId,
    hadMoreThanOneMessage: false,
    lastFetchedAt: null,
    isLastMessageFromRep: null,
    lastMessageFromRepAt: null,
    lastMessageFromProspectAt: null,
  }
};

// Add new types for conversation states
export enum ConversationExchangeState {
  HAS_HAD_EXCHANGE = 'HAS_HAD_EXCHANGE',
  NO_EXCHANGE = 'NO_EXCHANGE'
}

export function getInitialConversationStatesState(): ConversationStatesState {
  const localStorageData = localStorage.getItem(LINKEDIN_STATES_STORAGE_KEY);
  return {
    states: JSON.parse(localStorageData || '{}'),
  };
}

export const conversationStatesInitialState: ConversationStatesState = {
    states: getInitialConversationStatesState().states,
};

export const conversationStatesSlice = createSlice({
    name: 'linkedinConversationStates',
    initialState: conversationStatesInitialState,
    reducers: {
        updateNote: (state, action: PayloadAction<{
            conversationId: ConversationId;
            note: string | null;
        }>) => {
            state.states[action.payload.conversationId.id] = {
              ...(state.states[action.payload.conversationId.id] || emptyConversationState(action.payload.conversationId)),
              note: action.payload.note,
              conversationId: action.payload.conversationId
            } 
        },
        updateTags: (state, action: PayloadAction<{
            conversationId: ConversationId;
            tags: string[];
        }>) => {
            state.states[action.payload.conversationId.id] = {
              ...(state.states[action.payload.conversationId.id] || emptyConversationState(action.payload.conversationId)),
              tags: action.payload.tags,
              conversationId: action.payload.conversationId
            } 
        },
        updateSnooze: (state, action: PayloadAction<{
            conversationId: ConversationId;
            snooze: number | null;
        }>) => {
            state.states[action.payload.conversationId.id] = {
              ...(state.states[action.payload.conversationId.id] || emptyConversationState(action.payload.conversationId)),
              snooze: action.payload.snooze,
              conversationId: action.payload.conversationId
            } 
        },
        updateArchived: (state, action: PayloadAction<{
            conversationId: ConversationId;
            isArchived: boolean;
        }>) => {
            state.states[action.payload.conversationId.id] = {
              ...(state.states[action.payload.conversationId.id] || emptyConversationState(action.payload.conversationId)),
              isArchived: action.payload.isArchived,
              conversationId: action.payload.conversationId
            } 
        },
        updateExchangeState: (state, action: PayloadAction<{
            conversationId: ConversationId;
            exchangeState: ConversationExchangeState;
        }>) => {
            state.states[action.payload.conversationId.id] = {
              ...(state.states[action.payload.conversationId.id] || emptyConversationState(action.payload.conversationId)),
              exchangeState: action.payload.exchangeState,
              conversationId: action.payload.conversationId
            } 
        },
        updateLastAnalyzedAt: (state, action: PayloadAction<{
            conversationId: ConversationId;
            lastAnalyzedAt: number | null;
        }>) => {
            state.states[action.payload.conversationId.id] = {
              ...(state.states[action.payload.conversationId.id] || emptyConversationState(action.payload.conversationId)),
              lastAnalyzedAt: action.payload.lastAnalyzedAt,
              conversationId: action.payload.conversationId,
              analyzed_version: ANALYZED_VERSION,
            } 
        },
        updatePartialConversationState: (state, action: PayloadAction<{
            conversationId: ConversationId;
            conversationState: Partial<ConversationState>;
        }>) => {
            state.states[action.payload.conversationId.id] = {
              ...(state.states[action.payload.conversationId.id] || emptyConversationState(action.payload.conversationId)),
              ...action.payload.conversationState,
              conversationId: action.payload.conversationId
            } 
        },
    }
});

export const {
    updateNote,
    updateTags,
    updateSnooze,
    updateArchived,
    updateExchangeState,
    updateLastAnalyzedAt,
    updatePartialConversationState,
} = conversationStatesSlice.actions;
