import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LinkedInConversation, LinkedInMessage } from "interfaces/linkedin";
import { ConversationId, ConversationIds, InboxType, SOURCE_TYPE } from "../types";

// Type to store conversations safely in Redux
export type ReduxSafeConversation = Omit<LinkedInConversation, 'createdAt' | 'lastActivityAt' | 'lastReadAt'> & {
  createdAt: number;
  lastActivityAt: number | null;
  lastReadAt: number | null;
};

// Convert conversation to Redux safe format
export function convertConversationToReduxSafe(conversation: LinkedInConversation): ReduxSafeConversation {
  return {
    ...conversation,
    createdAt: conversation.createdAt.valueOf(),
    lastActivityAt: conversation.lastActivityAt?.valueOf() ?? null,
    lastReadAt: conversation.lastReadAt?.valueOf() ?? null,
  };
}

// Convert conversation from Redux safe format
export function convertConversationFromReduxSafe(conversation: ReduxSafeConversation): LinkedInConversation {
  return {
    ...conversation,
    createdAt: new Date(conversation.createdAt),
    lastActivityAt: conversation.lastActivityAt ? new Date(conversation.lastActivityAt) : null,
    lastReadAt: conversation.lastReadAt ? new Date(conversation.lastReadAt) : null,
  } as LinkedInConversation;
}


// State interfaces with proper typing
export interface InboxState {
  conversationIds: ConversationIds
  nextCursor: string | null;
  continues: boolean;
  isLoading: boolean;
  error: string | null;
  initialFetch: boolean;
  isPolling: boolean;
  pollingCursor: string | null;
}

export interface ConversationsState {
  conversations: Record<string, {
    conversation: ReduxSafeConversation | null;
    loadOlderMessagesPrevCursor: string | null;
    loadNewerMessagesSyncToken: string | null;
    isLoading: boolean;
    isCheckingForNewMessages: boolean;
    error: string | null;
  }>;
  conversationsLoaded: Set<string>;
  inboxState: Record<InboxType, InboxState>;
}

// Initial states
const conversationsInitialState: ConversationsState = {
  conversations: {},
  inboxState: {
    [InboxType.ALL]: {
      conversationIds: [],
      nextCursor: null,
      continues: true,
      isLoading: false,
      error: null,
      initialFetch: false,
      isPolling: false,
      pollingCursor: null,
    },
    [InboxType.UNREAD]: {
      conversationIds: [],
      nextCursor: null,
      continues: true,
      isLoading: false,
      error: null,
      initialFetch: false,
      isPolling: false,
      pollingCursor: null,
    },
  },
  conversationsLoaded: new Set()
};

export const chatConversationsSlice = createSlice({
    name: 'linkedinConversations',
    initialState: conversationsInitialState,
    reducers: {
      addMessageToConversation: (state, action: PayloadAction<{ 
        conversationId: ConversationId;
        messages: LinkedInMessage[];
      }>) => {
        const { conversationId, messages } = action.payload;
        // make sure to add the messages to the conversation in time order and remove duplicates
        const conversation = state.conversations[conversationId.id];
        if (!conversation) return;
        const existingMessages = conversation.conversation?.messages || [];
        const existingMessageUrnSet = new Set(existingMessages.map(m => m.entityUrn));
        const newMessages = messages.filter(m => !existingMessageUrnSet.has(m.entityUrn));
        const allMessages = [...existingMessages, ...newMessages].sort((a, b) => a.deliveredAt.getTime() - b.deliveredAt.getTime());
        conversation.conversation!.messages = allMessages;
      },
      addConversations: (state, action: PayloadAction<{ 
        inboxType: InboxType,
        conversations: LinkedInConversation[] 
      }>) => {
        const { inboxType, conversations } = action.payload;
        const filteredConversations = conversations.filter((x) => !x.conversationTypeText?.text.includes("Sponsored"))
        filteredConversations.forEach(conversation => {
          const currentConversation = state.conversations[conversation.entityUrn];
          if (!currentConversation) {
            state.conversations[conversation.entityUrn] = {
              conversation: convertConversationToReduxSafe(conversation),
              loadOlderMessagesPrevCursor: conversation._loadOlderMessagesPrevCursor ?? null,
              loadNewerMessagesSyncToken: conversation._loadNewerMessagesSyncToken ?? null,
              isLoading: false,
              isCheckingForNewMessages: false,
              error: null
            };
          } else {
            if (!conversation.messages.length || !currentConversation.conversation) return
            const messages = currentConversation.conversation?.messages || []
            const messagesSet = new Set(messages.map(m => m.entityUrn));
            const newMessages = conversation.messages.filter(m => !messagesSet.has(m.entityUrn));
            const allMessages = [...messages, ...newMessages].sort((a, b) => a.deliveredAt.getTime() - b.deliveredAt.getTime());
            state.conversations[conversation.entityUrn] = {
              ...currentConversation,
              conversation: {
                ...convertConversationToReduxSafe(conversation),
                messages: allMessages,
              },
              loadNewerMessagesSyncToken: conversation._loadNewerMessagesSyncToken ?? null,
            }
          }
        });
        const allConversationIds: string[] = Array.from(new Set([...state.inboxState[inboxType].conversationIds.map(c => c.id), ...filteredConversations.map(c => c.entityUrn)]))
        const newConversationIds: ConversationIds = allConversationIds.map(id => ({
          id,
          lastActivity: (state.conversations[id]?.conversation?.lastActivityAt || state.conversations[id]?.conversation?.createdAt || 0).valueOf(),
          sourceType: SOURCE_TYPE.CHAT
        })).sort((a, b) => {
          if (!state.conversations[a.id] || !state.conversations[b.id]) return 0;
          const aDate = new Date(state.conversations[a.id]?.conversation?.lastActivityAt || state.conversations[a.id]?.conversation?.createdAt || 0);
          const bDate = new Date(state.conversations[b.id]?.conversation?.lastActivityAt || state.conversations[b.id]?.conversation?.createdAt || 0);
          return bDate.getTime() - aDate.getTime();
        });
        state.inboxState[inboxType].conversationIds = newConversationIds
      },
      updateInboxState: (state, action: PayloadAction<{
        inboxType: InboxType,
        update: Partial<InboxState>
      }>) => {
        const { inboxType, update } = action.payload;
        state.inboxState[inboxType] = {
          ...state.inboxState[inboxType],
          ...update
        };
      },
      setLoading: (state, action: PayloadAction<{
        inboxType: InboxType,
        isLoading: boolean;
      }>) => {
        const { inboxType, isLoading } = action.payload;
        state.inboxState[inboxType].isLoading = isLoading;
      },
      setError: (state, action: PayloadAction<{
        inboxType: InboxType,
        error: string | null;
      }>) => {  
        const { inboxType, error } = action.payload;
        state.inboxState[inboxType].error = error;
      },
      updateConversation: (state, action: PayloadAction<{
        entityUrn: string;
        update: Partial<{
          conversation: ReduxSafeConversation | null;
          loadOlderMessagesPrevCursor: string | null;
          loadNewerMessagesSyncToken: string | null;
          isLoading: boolean;
          isCheckingForNewMessages: boolean;
          error: string | null;
        }>;
      }>) => {
        const { entityUrn, update } = action.payload;
        state.conversations[entityUrn] = {
          ...(state.conversations[entityUrn] || {}),
          ...update
        };
        if (update.conversation) {
          state.conversationsLoaded.add(entityUrn);
        }
      },
      addToConversation: (state, action: PayloadAction<{
        id: string;
        message: LinkedInMessage;
      }>) => {
        const { id, message } = action.payload;
        // check if the message is already in the conversation
        const existingMessage = state.conversations[id]?.conversation?.messages.find(m => m.entityUrn === message.entityUrn);
        if (existingMessage) return;
        state.conversations[id].conversation?.messages.push(message);
        state.conversations[id].conversation?.messages.sort((a, b) => a.deliveredAt.valueOf() - b.deliveredAt.valueOf());
      },
      updateConversationReadState: (state, action: PayloadAction<{
        id: string;
        read: boolean;
      }>) => {
        const { id, read } = action.payload;
        const conversation = state.conversations[id]?.conversation;
        if (!conversation) return;
        conversation.read = read;
      },
      removeFromInbox: (state, action: PayloadAction<{
        inboxType: InboxType;
        id: string;
      }>) => {
        const { inboxType, id } = action.payload;
        state.inboxState[inboxType].conversationIds = state.inboxState[inboxType].conversationIds.filter(c => c.id !== id);
      },
      addToInbox: (state, action: PayloadAction<{
        inboxType: InboxType;
        conversation: ConversationId;
      }>) => {
        const { inboxType, conversation } = action.payload;
        state.inboxState[inboxType].conversationIds.push(conversation);
      },
      addEmojiReaction: (state, action: PayloadAction<{
        conversationId: ConversationId;
        messageId: string;
        emoji: string;
      }>) => {
        const { conversationId, messageId, emoji } = action.payload;
        const conversation = state.conversations[conversationId.id];
        if (!conversation) return;
        const message = conversation.conversation?.messages.find(m => m.entityUrn === messageId);
        if (!message) return;
        message.reactionSummaries.push({
          emoji,
          count: 1,
          firstReactedAt: new Date(),
          viewerReacted: true,
          _type: "com.linkedin.messenger.ReactionSummary"
        });
      },
      removeEmojiReaction: (state, action: PayloadAction<{
        conversationId: ConversationId;
        messageId: string;
        emoji: string;
    }>) => {
      const { conversationId, messageId, emoji } = action.payload;
      const conversation = state.conversations[conversationId.id];
      if (!conversation) return;
      const message = conversation.conversation?.messages.find(m => m.entityUrn === messageId);
      if (!message) return;
      message.reactionSummaries = message.reactionSummaries.filter(r => r.emoji !== emoji);
    },
    setPolling: (state, action: PayloadAction<{
      inboxType: InboxType;
      isPolling: boolean;
    }>) => {
      const { inboxType, isPolling } = action.payload;
      state.inboxState[inboxType].isPolling = isPolling;
    },
    setPollingCursor: (state, action: PayloadAction<{
      inboxType: InboxType;
      pollingCursor: string | null;
    }>) => {
      const { inboxType, pollingCursor } = action.payload;
      state.inboxState[inboxType].pollingCursor = pollingCursor;
    }
  }
});

  export const { 
    addConversations, updateInboxState, setLoading, 
    setError, updateConversation, 
    addToConversation, updateConversationReadState,
    removeFromInbox, addToInbox,
    addEmojiReaction, removeEmojiReaction,
    setPolling, setPollingCursor
  } = chatConversationsSlice.actions;