import React, { useState, useMemo, useEffect } from 'react';
import { Chip, FormControl, Select, MenuItem, SelectChangeEvent, TextField } from '@mui/material';
import { Search } from 'lucide-react';
import { SimplifiedMultiSelect } from 'components/Selectors/SimplifiedMultiSelect';
import { MultiUserSelect, MultiUserSelector } from 'components/Selectors/MulltiUserSelector';
import { connect } from 'react-redux';
import { RootState } from 'store';
import { ReduxSafeVisibleAccountsResult, UserGroupInfo, convertFromReduxSafeUserResult, convertFromReduxSafeUserState, convertUserDataResultToReduxSafe, reloadScopes, updateScopes, updateVisibleAccounts } from 'lib/redux/store';
import { ACCOUNT_COVERAGE_ID_ALL, ACCOUNT_COVERAGE_ID_NA, ScopeAccountCoverageType, ScopeHolderType, ScopeResult, ScopeType, ScopeUserCoverageType, ScopeV2, ScopeV2Result, USER_COVERAGE_ID_ALL, UserDataResult, VisibleAccountsResult } from 'interfaces/services';
import { Loader } from 'components/Loader';
import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { PropaneSharp } from '@mui/icons-material';
import { getServicesManager } from 'services';

interface User {
  id: number;
  name: string;
  access: string[];
  isActive: boolean;
  canDial: boolean;
  isAdmin: boolean;
}

interface TeamData {
  [key: string]: User[];
}

const Toggle: React.FC<{ disabled: boolean, value: boolean; onChange: () => void; label: string }> = ({ disabled, value, onChange, label }) => (
  <button
    disabled={disabled}
    onClick={onChange}
    className={`relative inline-flex h-4 w-8 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 ${
      value ? 'bg-blue-600' : 'bg-gray-200'
    }`}
    aria-label={label}
  >
    <span
      className={`inline-block h-3 w-3 transform rounded-full bg-white transition-transform ${
        value ? 'translate-x-4' : 'translate-x-1'
      }`}
    />
  </button>
);

type TeamManagementProps = {
  viewingUser: UserDataResult | null,
  visibleAccounts: ReduxSafeVisibleAccountsResult | null,
  all_users: UserDataResult[],
  sortedUserGroupInfo: UserGroupInfo[],
  userGroupsLoaded: boolean,
  unassignedUsers: UserDataResult[],
  scopesResult: ScopeV2Result | null,
  dispatch: Dispatch<AnyAction>
}

function TeamManagementImpl({ viewingUser, visibleAccounts, all_users, sortedUserGroupInfo, userGroupsLoaded, unassignedUsers, scopesResult, dispatch }: TeamManagementProps) {
  const [filterStatus, setFilterStatus] = useState<'all' | 'active' | 'inactive'>('all');
  const [searchQuery, setSearchQuery] = useState('');

  const activeUsers = useMemo(() => {
    return all_users.filter(user => user.team_is_active).length;
  }, [all_users]);

  const activeAndCanDialUsers = useMemo(() => {
    return all_users.filter(user => user.team_is_active && user.can_dial).length;
  }, [all_users]);

  useEffect(() => {
    if (!scopesResult) reloadScopes(dispatch)
  }, [])

  const updateActive = (user: UserDataResult, isActive: boolean) => {
    if (!visibleAccounts) return
    dispatch(updateVisibleAccounts({...visibleAccounts, 'users': visibleAccounts.users.map((u) => u.user_id === user.user_id ? {...u, 'team_is_active': isActive} : u)}))
    // update user
    getServicesManager().putSeats(user.user_id, isActive, user.can_dial)
  }

  const updateCanDial = (user: UserDataResult, canDial: boolean) => {
    if (!visibleAccounts) return
    dispatch(updateVisibleAccounts({...visibleAccounts, 'users': visibleAccounts.users.map((u) => u.user_id === user.user_id ? {...u, 'can_dial': canDial} : u)}))
    // update user
    getServicesManager().putSeats(user.user_id, user.team_is_active, canDial)
  }

  const getDefaultAdminScope = (user: UserDataResult): ScopeV2 => {
    return {
      holder_id: user.user_id,
      holder_type: ScopeHolderType.USER,
      user_coverage_type: ScopeUserCoverageType.TEAM,
      scope_type: ScopeType.ADMIN_WRITE,
      holder_team_id: user.team_id,
      user_coverage_id: user.team_id,
      account_coverage_type: ScopeAccountCoverageType.NA,
      account_coverage_id: ACCOUNT_COVERAGE_ID_NA
    }
  }
  const getCurrentAdminScope = (user: UserDataResult): ScopeV2 | undefined => {
    return scopesResult?.scopes.find(s => s.scope_type === ScopeType.ADMIN_WRITE && s.holder_id === user.user_id && s.holder_type === ScopeHolderType.USER && s.user_coverage_type === ScopeUserCoverageType.TEAM)
  }
  const updateAdminState = (user: UserDataResult, isAdmin: boolean) => {
    if (!scopesResult) return
    const currentScope = getCurrentAdminScope(user)
    if (!isAdmin) {
      if (!currentScope) return
      dispatch(updateScopes({...scopesResult, 'scopes': scopesResult.scopes.filter(s => JSON.stringify(s) !== JSON.stringify(currentScope))}))
      getServicesManager().updateScope(currentScope, true)
    } else {
      if (currentScope) return
      const newScope = getDefaultAdminScope(user)
      dispatch(updateScopes({...scopesResult, 'scopes': [...scopesResult.scopes, newScope]}))
      getServicesManager().updateScope(newScope)
    }
  }

  const filteredUsers = useMemo(() => {
    return all_users.filter(user => {
      // find group they are part of
      const matchingGroups = sortedUserGroupInfo.filter(g => g.directUserIds.includes(user.user_id))
      const groupNames = matchingGroups.map(g => g.group.user_group_name)
      const hasMatchingGroupName = groupNames.some(name => name.toLowerCase().includes(searchQuery.toLowerCase()))
      const matchesSearch = user.user_name.toLowerCase().includes(searchQuery.toLowerCase());
      const matchesFilter = 
        filterStatus === 'all' ? true :
        filterStatus === 'active' ? user.team_is_active :
        !user.team_is_active;
      return (hasMatchingGroupName || matchesSearch) && matchesFilter;
    });
  }, [all_users, filterStatus, searchQuery]);

  const unassignedFilteredUsers = useMemo(() => {
    const filtered_user_ids = filteredUsers.map(u => u.user_id)
    return unassignedUsers.filter(u => filtered_user_ids.includes(u.user_id))
  }, [filteredUsers, unassignedUsers])

  const filteredGroups = useMemo(() => {
    return sortedUserGroupInfo.filter((group) => {
      const matchesSearch = group.group.user_group_name.toLowerCase().includes(searchQuery.toLowerCase());
      const userMatches = filteredUsers.some((v) => group.directUserIds.includes(v.user_id));
      return group.directUserIds.length > 0 && ((matchesSearch && searchQuery !== '') || userMatches);
    });
  }, [sortedUserGroupInfo, filteredUsers, searchQuery]);

  if (!userGroupsLoaded || !scopesResult) return <Loader />

  const convertScopeToMultiUserSelect = (scope: ScopeV2): MultiUserSelect => {
    switch (scope.user_coverage_type) {
      case ScopeUserCoverageType.TEAM: return { team_id: scope.user_coverage_id, 'user_group_id': null, 'user_id': null }
      case ScopeUserCoverageType.USER: return { user_id: scope.user_coverage_id, 'team_id': null, 'user_group_id': null }
      case ScopeUserCoverageType.USER_GROUP: return { user_group_id: scope.user_coverage_id, 'team_id': null, 'user_id': null }
      case ScopeUserCoverageType.ALL: return { team_id: viewingUser?.team_id ?? null, user_group_id: null, user_id: null }
    }
  }
  const convertMultiUserSelectToScope = (select: MultiUserSelect, id: string, scopeType: ScopeHolderType): ScopeV2 => {
    return {
      scope_type: ScopeType.SESSIONS_READ,
      holder_id: id,
      holder_type: scopeType,
      holder_team_id: viewingUser?.team_id ?? '',
      user_coverage_type: select.team_id ? ScopeUserCoverageType.ALL : select.user_group_id ? ScopeUserCoverageType.USER_GROUP : ScopeUserCoverageType.USER,
      user_coverage_id: (select.team_id ? USER_COVERAGE_ID_ALL : select.user_group_id ? select.user_group_id : select.user_id) ?? '',
      account_coverage_type: ScopeAccountCoverageType.ALL,  // # will be NA for scopes other than session_read
      account_coverage_id: ACCOUNT_COVERAGE_ID_ALL  // takes special values ACCOUNT_COVERAGE_ID_ALL, ACCOUNT_COVERAGE_ID_NA for corresponding types
    }
  }

  const getTeamReadScopes = (): ScopeV2[] => {
    return scopesResult.scopes.filter(s => s.holder_id === viewingUser?.team_id && s.scope_type === ScopeType.SESSIONS_READ && s.holder_type === ScopeHolderType.TEAM)
  }

  const getSelectedReadScopesForTeam = (): MultiUserSelect[] => {
    const readScopes = getTeamReadScopes()
    return readScopes.map((v) => convertScopeToMultiUserSelect(v))
  }

  const updateSelectedReadScopeTeam = (selected: MultiUserSelect[]) => {
    // need to delete some scopes and add some scopes...
    const currentReadScopes = getTeamReadScopes()
    const toDeleteScopes = currentReadScopes.filter((v) => {
      const multi_select = convertScopeToMultiUserSelect(v)
      return !selected.some((s) => s.team_id === multi_select.team_id && s.user_id === multi_select.user_id && s.user_group_id === multi_select.user_group_id)
    })
    const toAddSelects = selected.filter((s) => {
      return !currentReadScopes.some((v) => {
        const multi_select = convertScopeToMultiUserSelect(v)
        return multi_select.team_id === s.team_id && multi_select.user_id === s.user_id && multi_select.user_group_id === s.user_group_id
      })
    }).map((s) => convertMultiUserSelectToScope(s, viewingUser?.team_id ?? '', ScopeHolderType.TEAM))
    dispatch(updateScopes({...scopesResult, 'scopes': [...scopesResult.scopes.filter((v) => !toDeleteScopes.includes(v)), ...toAddSelects]}))
    for (const scope of toDeleteScopes) {
      getServicesManager().updateScope(scope, true)
    }
    for (const scope of toAddSelects) {
      getServicesManager().updateScope(scope)
    }
  }


  const getCurrentReadScopes = (id: string, type: ScopeHolderType): ScopeV2[] => {
    return scopesResult.scopes.filter(s => s.holder_id === id && s.scope_type === ScopeType.SESSIONS_READ && s.holder_type === type && (s.user_coverage_id !== id || s.user_coverage_type !== ScopeUserCoverageType.USER)) 
  }
  const getSelectedReadScopes = (id: string, type: ScopeHolderType): MultiUserSelect[] => {
    const readScopes = getCurrentReadScopes(id, type)
    return readScopes.map((v) => convertScopeToMultiUserSelect(v))
  }
  const updateSelectedReadScope = (id: string, scopeType: ScopeHolderType, selected: MultiUserSelect[]) => {
    // need to delete some scopes and add some scopes...
    const currentReadScopes = getCurrentReadScopes(id, scopeType)
    const toDeleteScopes = currentReadScopes.filter((v) => {
      const multi_select = convertScopeToMultiUserSelect(v)
      return !selected.some((s) => s.team_id === multi_select.team_id && s.user_id === multi_select.user_id && s.user_group_id === multi_select.user_group_id)
    })
    const toAddSelects = selected.filter((s) => {
      return !currentReadScopes.some((v) => {
        const multi_select = convertScopeToMultiUserSelect(v)
        return multi_select.team_id === s.team_id && multi_select.user_id === s.user_id && multi_select.user_group_id === s.user_group_id
      })
    }).map((s) => convertMultiUserSelectToScope(s, id, scopeType))
    dispatch(updateScopes({...scopesResult, 'scopes': [...scopesResult.scopes.filter((v) => !toDeleteScopes.includes(v)), ...toAddSelects]}))
    for (const scope of toDeleteScopes) {
      getServicesManager().updateScope(scope, true)
    }
    for (const scope of toAddSelects) {
      getServicesManager().updateScope(scope)
    }
  }

  const renderUser = (userId: string) => {
    const user = filteredUsers.find(u => u.user_id === userId);
    if (!user) return null;
    const isAdmin = getCurrentAdminScope(user) !== undefined
    return <tr key={user.user_id} className="hover:bg-gray-50" style={{
      backgroundColor: user.user_id === viewingUser?.user_id ? 'rgba(79, 70, 229, 0.1)' : undefined
    }}>
      <td className="px-3 py-1 whitespace-nowrap text-sm text-gray-900 pl-6">
        {user.user_name}
      </td>
      <td className="px-3 py-1 whitespace-nowrap">
        <FormControl fullWidth size="small">
          <MultiUserSelector 
          selectedUserOrGroups={getSelectedReadScopes(user.user_id, ScopeHolderType.USER)}
          onSelectedUserOrGroupsChange={(selected) => updateSelectedReadScope(user.user_id, ScopeHolderType.USER, selected)}
          />
      </FormControl>
      </td>
      <td className="px-3 py-1 whitespace-nowrap text-center">
        <Toggle
          disabled={user.user_id === viewingUser?.user_id}
          value={user.team_is_active}
          onChange={() => updateActive(user, !user.team_is_active)}
          label="Active toggle"
        />
      </td>
      <td className="px-3 py-1 whitespace-nowrap text-center">
        <Toggle
          disabled={false}
          value={user.can_dial}
          onChange={() => updateCanDial(user, !user.can_dial)}
          label="Can dial toggle"
        />
      </td>
      <td className="px-3 py-1 whitespace-nowrap text-center">
        <Toggle
          disabled={user.user_id === viewingUser?.user_id}
          value={isAdmin}
          onChange={() => updateAdminState(user, !isAdmin)}
          label="Admin toggle"
        />
      </td>
    </tr>
  }

  return (
    <div className="p-4 bg-gray-50">
      <div className="mb-4 flex gap-8">
        <div className="bg-white rounded-lg border border-gray-200 p-3">
          <div className="text-sm text-gray-500">Active Users</div>
          <div className="text-2xl font-semibold">{activeUsers}</div>
        </div>
        <div className="bg-white rounded-lg border border-gray-200 p-3">
          <div className="text-sm text-gray-500">Active Users with Dial Access</div>
          <div className="text-2xl font-semibold">{activeAndCanDialUsers}</div>
        </div>
      </div>

      <div className="mb-4 flex items-center justify-between">
          <div className="relative">
            <Search className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400 h-4 w-4" />
            <input
              type="text"
              placeholder="Search users..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="pl-8 pr-2 py-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
            />
          </div>
          <FormControl size="small" className="min-w-[120px]">
            <Select
              value={filterStatus}
              onChange={(e) => setFilterStatus(e.target.value as typeof filterStatus)}
              className="text-sm"
            >
              <MenuItem value="all">All Users</MenuItem>
              <MenuItem value="active">Active</MenuItem>
              <MenuItem value="inactive">Inactive</MenuItem>
            </Select>
          </FormControl>
      </div>

      <div className="bg-white rounded-lg border border-gray-200">
        <table className="min-w-full divide-y divide-gray-200">
          <thead>
            <tr className="bg-gray-50">
              <th scope="col" className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">User/Team</th>
              <th scope="col" className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Read Access</th>
              <th scope="col" className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Active</th>
              <th scope="col" className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Can Dial</th>
              <th scope="col" className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Admin</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
          <tr className="bg-blue-50">
              <td colSpan={1} className="px-3 py-1 text-left text-xs font-medium text-gray-900">
                Overall Team
              </td>
              <td colSpan={1} className="px-3 py-1 whitespace-nowrap">
                <FormControl fullWidth size="small">
                  <MultiUserSelector 
                  selectedUserOrGroups={getSelectedReadScopesForTeam()}
                  onSelectedUserOrGroupsChange={(selected) => updateSelectedReadScopeTeam(selected)}
                  />
                </FormControl>
              </td>
              <td colSpan={3} className=''></td>
            </tr>
            {filteredGroups.map((group) => (
              <React.Fragment key={group.group.user_group_id}>
                <tr className="bg-gray-50">
                  <td colSpan={1} className="px-3 py-1 text-left text-xs font-medium text-gray-900">
                    {group.group.user_group_name}
                  </td>
                  <td colSpan={1} className="px-3 py-1 whitespace-nowrap">
                      <FormControl fullWidth size="small">
                        <MultiUserSelector 
                        selectedUserOrGroups={getSelectedReadScopes(group.group.user_group_id, ScopeHolderType.USER_GROUP)}
                        onSelectedUserOrGroupsChange={(selected) => updateSelectedReadScope(group.group.user_group_id, ScopeHolderType.USER_GROUP, selected)}
                        />
                    </FormControl>
                    </td>
                    <td colSpan={3} className=''></td>
                </tr>
                {group.directUserIds.map(user => renderUser(user))}
              </React.Fragment>
            ))}
            {unassignedFilteredUsers.length > 0 && <tr className="bg-gray-50">
              <td colSpan={5} className="px-3 py-1 text-left text-xs font-medium text-gray-900">
                Unassigned
              </td>
            </tr>}
            {unassignedFilteredUsers.map(user => renderUser(user.user_id))}
          </tbody>
        </table>
      </div>
    </div>
  );
}


export const TeamManagement = connect((state: RootState) => {
  const assignedUserIds = new Set()
  state.userGroupInfo.sortedGroupInfo.forEach(g => g.directUserIds.forEach(userId => assignedUserIds.add(userId)))
  return {
    viewingUser: convertFromReduxSafeUserState(state.user),
    visibleAccounts: state.visibleAccounts.value,
    all_users: state.visibleAccounts.value?.users.map((v) => convertFromReduxSafeUserResult(v)) ?? [],
    sortedUserGroupInfo: state.userGroupInfo.sortedGroupInfo,
    userGroupsLoaded: state.userGroupInfo.hasLoaded,
    unassignedUsers: (state.visibleAccounts.value?.users?.filter(u => !assignedUserIds.has(u.user_id)).sort(
        (a, b) => a.user_name.localeCompare(b.user_name) || a.created_at.valueOf() - b.created_at.valueOf()
    ) ?? []).map((v) => convertFromReduxSafeUserResult(v)),
    scopesResult: state.scope.value,
  }
})(TeamManagementImpl)