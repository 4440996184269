import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const ANALYTICS_KEY = 'analytics';

// by day... 

// for each conversation - need to know the last time it was analyzed, and the earliest_as_of data for its analysis..
// need to have a sense of number of messages sent and received by time slice (probably just by day)
 
interface AnalyzeState {
  analyzed: Record<string, boolean>;
  analyzing: Record<string, boolean>;
  initialAnalyticsLoaded: boolean;
  initialAnalyticsLoadAttempted: boolean;
}

const initialState: AnalyzeState = {
  analyzing: {},
  analyzed: {},
  initialAnalyticsLoaded: false,
  initialAnalyticsLoadAttempted: false,
};

export const analyzeSlice = createSlice({
  name: 'linkedinAnalyze',
  initialState,
  reducers: {
    setAnalyzing: (state, action: PayloadAction<{
      conversationId: string;
      isAnalyzing: boolean;
    }>) => {
      const { conversationId, isAnalyzing } = action.payload;
      state.analyzing[conversationId] = isAnalyzing;
    },
    setAnalyzed: (state, action: PayloadAction<{
      conversationId: string;
      isAnalyzed: boolean;
    }>) => {
      const { conversationId, isAnalyzed } = action.payload;
      state.analyzed[conversationId] = isAnalyzed;
    },
    resetAnalyzing: (state) => {
      state.analyzing = {};
    },
    setInitialAnalyticsLoaded: (state, action: PayloadAction<boolean>) => {
      state.initialAnalyticsLoaded = action.payload;
    },
    setInitialAnalyticsLoadAttempted: (state, action: PayloadAction<boolean>) => {
      state.initialAnalyticsLoadAttempted = action.payload;
    }
  }
});

export const { setAnalyzing, setAnalyzed, resetAnalyzing, setInitialAnalyticsLoaded, setInitialAnalyticsLoadAttempted } = analyzeSlice.actions;